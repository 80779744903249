/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import tenantModule from '@/store/modules/tenantModule';
import {
  doJobDashboardSearchMock,
  doJobDashboardStatusChangeMock,
  doStatusTransitionMock,
  fetchInterviewRecruiterCommentsMock,
  fetchInterviewRecruiterDetailMock,
  fetchJobDashboardDetailsMock,
  fetchJobListMock,
  saveInterviewRecruiterCommentMock,
  updateUserStatusMock
} from './mock/api-mock';
import {
  ActionLogRequest,
  ActivateJobSiteRequest,
  AdCampaignInfoRequestDto,
  AdCampaignRequestDto,
  AddAssessmentGroupDto,
  AddParticipantsDto,
  AdditionalProfileInfoDto,
  AiDisclaimerRequestDto,
  AllChatMessageRequestDto,
  AllChatMessageResponseDto,
  AllChatMessagescountResponse,
  ApplicantSearchRequest,
  ApplicantSearchResult,
  ApplicantSearchSelectableValues,
  AssessmentDetailRequestDto,
  AssessmentRunDetailDto,
  AssessmentRunDto,
  AssessmentSearchRequestDto,
  AssessmentSearchResponseDto,
  AssignUserRequestDto,
  BulkMessageRecipientResponseDto,
  BulkMessageRecipientSearchRequestDto,
  BulkMessageRequestDto,
  BulkMessageSearchRequestDto,
  BulkMessageSearchResponseDto,
  CampaignInfoRequestDto,
  CampaignQuestionnaireRequestDto,
  CampaignQuestionnaireTemplateDto,
  CampaignRequestDto,
  CampaignStatusChangeRequest,
  CandidateLanguageDto,
  CandidateSalutationDto,
  CandidateTagCreateAssignGroupRequest,
  CandidateTagCreateRequest,
  CandidateTagDto,
  CandidateTagsUpdateRequest,
  ChangePasswordRequest,
  ChannelTemplateMessageDto,
  ChartDataDto,
  ChatMessage,
  ChatMessageRequest,
  ChatMessageResponse,
  ClusterJobsUpdateDto,
  CommentRequest,
  CommentResponse,
  Contact,
  ContactsImportResultDto,
  ContactsImportSearchRequest,
  CopyJobRequest,
  CopyJobTemplateResult,
  CountriesOptionResponse,
  CreateJobSiteRequest,
  CreateSiteRequest,
  CreateTemplateMessageDto,
  DeactivateTenantDto,
  DefaultJobDescriptionDto,
  DirectAssignUserGroup,
  DirectDisclaimerResponseDto,
  DisclaimerTargetResponse,
  DleteAfterDto,
  ExternalCommentRequest,
  ExternalJobSetting,
  ExternalTrackingDto,
  FilterResponseDto,
  FunnelDataDto,
  FunnelReportRequest,
  FunnelReportResponse,
  GetShareInterviewContent,
  GetShareInterviewRequest,
  GetShareInterviewResponse,
  GroupCandidateTagDto,
  GroupDto,
  GroupTagAssessmentsRequestDto,
  GroupTagCandidatesRequestDto,
  GroupTagRecipientsRequestDto,
  GroupTransitionRequest,
  GroupTransitionResponse,
  GroupsResponseDto,
  IJAWInterviewRequest,
  IJobInfoRequest,
  ILabelValue,
  InitMessage,
  Interview,
  InterviewActionProtocolResponse,
  InterviewAttachmentResponse,
  InterviewParamsDto,
  JAWApplicationConfig,
  JAWConfig,
  JAWConfigByTenantRequest,
  JAWConfigRequest,
  JAWInterviewRequest,
  JAWPropertyConfig,
  JAWStepTemplate,
  JobCategoryResponse,
  JobClusterItem,
  JobClusterOriginsRequestDto,
  JobClusterRequest,
  JobClusterUrlByGroupRequestDto,
  JobClusterUrlItem,
  JobDashboardRequest,
  JobDashboardResponse,
  JobDashboardSearchRequest,
  JobDashboardSearchResponse,
  JobDashboardStatusChangeRequest,
  JobDashboardStatusChangeResponse,
  JobInfoRequest,
  JobInfoResponse,
  JobListRequest,
  JobListResponse,
  JobOriginRequestDto,
  JobPostingSitesCountDto,
  JobStatusChangeRequest,
  JobStatusChangeResponse,
  JobTemplateInterviewRequest,
  JobTemplateListDto,
  JobTemplateResponseDto,
  JobTemplateTag,
  JobTemplateTagRequest,
  JobUrl,
  JobUrlByGroupRequestDto,
  JobUrlsRequest,
  JobUrlsResponse,
  JobsMainCredentials,
  KleinAnzeigenPostingRequestDto,
  LoadInterviewDetailRequest,
  LoadLanguageTemplatesRequest,
  LoadTemplatesRequest,
  LoginRequest,
  Message,
  MessageCenterContact,
  MessageCenterContactSearchRequestDto,
  MessageCenterContactSearchResponseDto,
  MessageTemplate,
  MessengerChannelDto,
  MetaLeadAuthCodeDto,
  MultipleGroupTransitionRequest,
  NewPasswordRequest,
  NotificationInfoDto,
  OnboardNewTenantRequest,
  OnboardNewTenantResponse,
  OnboardRemoteSystemParamsRequestDto,
  Origin,
  OriginSelectionResponse,
  PagePermissionDto,
  PrivacyConfTextResponseDto,
  PrivacyConfirmationRequest,
  PrivacyConfirmationResponse,
  PublishedPlatformRequest,
  QuestionnaireDetailDto,
  QuestionnaireSearchItemDto,
  QuestionnaireSearchRequestDto,
  QuestionnaireSearchResponseDto,
  QuestionnaireType,
  RecentApplication,
  RecruitMeMessagesResponse,
  RecruiterInterview,
  RejectInterviewRequest,
  RemoteSystemRequestDto,
  ReportTopApplicantResponse,
  ReportsFilterResponse,
  ResetPasswordRequest,
  Role,
  SalesUserRequest,
  SalesUserResponse,
  SaveJobClusterRequest,
  SelectAllAssessmentsRequestDto,
  SelectAllContactsAndTagsRequestDto,
  SelectAllRecipientsRequestDto,
  SelectableJob,
  SelectableJobTemplate,
  SelectableSite,
  SelectableUser,
  SendChatMessageRequest,
  SetChatTranslationRequest,
  ShareInterview,
  ShareInterviewRequest,
  ShortUrlEntity,
  ShortUrlRequest,
  Site,
  SiteSelectionRequest,
  SiteSelectionResponse,
  StackChartReportResponse,
  StartMessageMediaJobRequest,
  StartWabaSetupRequestDto,
  StepMedia,
  StepTemplateGroup,
  SuperAdminATSLoginDto,
  TFAVerificationDto,
  TagMangementDto,
  Tenant,
  TenantInfo,
  TenantLite,
  TenantLoginInfo,
  TenantMessageInfo,
  TransitionStatusRequest,
  TranslationPropTextDto,
  TranslationTextDto,
  UpdateCandidateName,
  UpdateContactGroupDto,
  UpdateCredentialRequest,
  UpdateInterviewSitesResponseDto,
  UpdateUserStatusRequest,
  User,
  UserSubscribedInterviewDto,
  WipeOutAuthCredentialsDto
} from './models';
import {
  activateJobSiteServer,
  addAssessmentGroupsServer,
  addParticipantsServer,
  applyAiDisclaimerServer,
  applyDisclaimerServer,
  archiveAssessmentRunServer,
  archiveJobClusterServer,
  archiveJobServer,
  archiveMessengerChannelServer,
  archiveQuestionnaireServer,
  assignTagsToMultipleAssessmentsServer,
  assignTagsToMultipleRecipientsServer,
  assignTagsToMultiplecandidatesServer,
  assignUserServer,
  bookmarkSiteServer,
  cancelAssessmentRunServer,
  cancelAssessmentServer,
  chatMessageUploadMediaServer,
  checkRemoteSystemParamsServer,
  clearJWTServer,
  confirmMessageReadServer,
  copyJobServer,
  copyJobTemplateServer,
  copyQuestionnaireServer,
  createCandidateTagAssignGroupServer,
  createCandidateTagServer,
  createNewContactServer,
  createNewGroupServer,
  createNewJobSiteServer,
  createNewTenantServer,
  createSalesTestUserServer,
  deactivateJobSiteServer,
  deactivateTenantServer,
  deleteActionProtocolCommentServer,
  deleteBulkMessageRecipientServer,
  deleteCandidateTagNameServer,
  deleteContactsImportServer,
  deleteGroupServer,
  deleteOriginServer,
  deleteSiteServer,
  deleteStartMessageMediaForJobServer,
  deleteStartMessageMediaServer,
  deleteUserServer,
  detectLanguageServer,
  directAssignUserServer,
  doAddExternalCommentServer,
  doChangePasswordByTokenServer,
  doChangePasswordServer,
  doGetReportApplicantsFunnelServer,
  doGetReportApplicantsPerJobServer,
  doGetReportInitialApplicantsPerJobServer,
  doGetReportNoOfApplicantsServer,
  doGetReportTopApplicantsServer,
  doJobDashboardSearchServer,
  doJobDashboardStatusChangeServer,
  doJobStatusChangeServer,
  doLogActionServer,
  doPasswordResetServer,
  doRejectInterviewsServer,
  doShareExistsServer,
  doShareInterviewServer,
  doStatusTransitionServer,
  doValidateNewPasswordTokenServer,
  exportAssessmentsServer,
  fetchActionProtocolServer,
  fetchAllAlternativeGroupsServer,
  fetchAllJobsServer,
  fetchAlternativeGroupsServer,
  fetchBenchmarkDataServer,
  fetchChangeableSitesServer,
  fetchCountriesOptionServer,
  fetchExternalCompanyNamesServer,
  fetchExternalJobInfosServer,
  fetchFunnelReportsServer,
  fetchGroupsServer,
  fetchInterviewAttachmentsServer,
  fetchInterviewDetailServer,
  fetchInterviewRecruiterCommentsServer,
  fetchInterviewRecruiterDetailServer,
  fetchJobCategoriesServer,
  fetchJobClustersFilterResultsServer,
  fetchJobDashboardDetailsServer,
  fetchJobInfoServer,
  fetchJobListServer,
  fetchJobMainsCredentialsServer,
  fetchJobPostingSiteServer,
  fetchJobUrlsServer,
  fetchJobsFilterResultsServer,
  fetchKleinanzeigenJobPostingsServer,
  fetchLanguageOptionServer,
  fetchLanguageOptionTemplateServer,
  fetchMoreCountriesOptionServer,
  fetchNewContactGroupsServer,
  fetchOriginFilterResultsServer,
  fetchPostingJobIdsServer,
  fetchPostingJobTitlesServer,
  fetchRemoteSystemFilterResultsServer,
  fetchReportsExcelExportServer,
  fetchSitesServer,
  fetchStackChartReportsServer,
  fetchTenantFilterResultsServer,
  fetchTenantInfoLiteServer,
  fetchTenantInfoServer,
  fetchTenantLoginInfoServer,
  fetchTenantMessageInfoServer,
  fetchUtmTagsFilterResultsServer,
  finalizeOnboardTenantServer,
  findAppPropsServer,
  findJobInterviewServer,
  findJobOriginSelectionsServer,
  findJobOriginsServer,
  findPropKeysByTenantServer,
  findPropKeysServer,
  getActiveQuestionnairesServer,
  getAdCampaignInfoServer,
  getAdCampaignJobSitesServer,
  getAdCampaignJobsServer,
  getAdCampaignsServer,
  getAdditionalProfileInfosServer,
  getAllChatMessagesCountServer,
  getAllJobClustersServer,
  getAllMessageCenterContactsServer,
  getAllMessagesServer,
  getApplicantSearchExcelExportServer,
  getApplicantSearchResultServer,
  getApplicantSearchSelectableValuesServer,
  getApplicationPropertiesServer,
  getAssessmentRunDetailServer,
  getAssessmentRunServer,
  getAssessmentRunsServer,
  getAssessmentUserGroupsServer,
  getAssignableGroupJobsServer,
  getBulkMessageRecipientsServer,
  getBulkMessagesServer,
  getCampaignInfoServer,
  getCampaignInterviewServer,
  getCampaignSettingsServer,
  getCampaignTemplateGroupsServer,
  getCampaignsServer,
  getCandidateAgeReportServer,
  getCandidateTagsServer,
  getCandidateTagsWithGroupServer,
  getChannelDetails,
  getChatMessagesServer,
  getChildGroupsServer,
  getContactsImportHistoryServer,
  getConversationReportsServer,
  getCustomTemplateLabelServer,
  getDashboardChartReportServer,
  getDefaultJobDescriptionServer,
  getDisclaimerTargetServer,
  getExternalTrackingServer,
  getExternalTrackingTypesServer,
  getFinalizeAtDateServer,
  getGroupsUserServer,
  getInfoNoteServer,
  getJobClusterServer,
  getJobClusterUrlsByGroupServer,
  getJobTemplateGroupsServer,
  getJobTemplateQuestionsServer,
  getJobTemplateServer,
  getJobTemplateStepsServer,
  getJobTemplateTagsServer,
  getJobTemplatesServer,
  getJobUrlsByGroupServer,
  getLanguageReportServer,
  getMediaMetaDataServer,
  getMessageCenterContactServer,
  getMessageCenterContactsServer,
  getMessageServer,
  getMessageTemplatesServer,
  getMessengerChannels,
  getNotificationInfoServer,
  getOnboardTenantByContactIdServer,
  getOnboardTenantByDealIdServer,
  getOriginReportServer,
  getOriginSelectionsServer,
  getOriginsServer,
  getPagePermissionsServer,
  getProductTypesServer,
  getQuestionnaireServer,
  getQuestionnairesServer,
  getRecentApplicationsServer,
  getRecruitMeMessagesServer,
  getRemoteSystemServer,
  getRemoteSystemTypesServer,
  getRoleBasedGroupsServer,
  getRoleServer,
  getRootGroupsServer,
  getSelectableJobTemplatesServer,
  getSelectableJobsServer,
  getSelectableSitesServer,
  getSelectableUserServer,
  getSharedEmailsServer,
  getSharedInterviewCommentsServer,
  getSharedInterviewContentServer,
  getSharedInterviewServer,
  getShortUrlTargetServer,
  getSingleContactServer,
  getSiteSelectionServer,
  getSitesServer,
  getTenantAdminUsersServer,
  getTenantGroupsServer,
  getTenantInfoServer,
  getTrackingEventTypesServer,
  getUserGroupsServer,
  getUserPreferredChatLanguageServer,
  getUserPreferredLanguageServer,
  getYesTextServer,
  hasMessengerChannelServer,
  initSalesSessionServer,
  initWebMessengerServer,
  initWhatsAppPageServer,
  isCreateTenantIdentifierValidServer,
  isCredServer,
  isDirectDisclaimerForGuidServer,
  isDirectDisclaimerServer,
  isSuperAdminATSLoginAllowedServer,
  loadExternalTrackingParamsServer,
  loadJobsForExternalChatServer,
  loadRemoteSystemParamsServer,
  loadTenantsForExternalChatServer,
  loadtemplateCandidateServer,
  loadtemplatesServer,
  multipleStatusTransitionServer,
  performLoginServer,
  permanentDeleteApplicantServer,
  processPagePermissionServer,
  removeContactServer,
  resetOnboardTenantServer,
  saveAdCampaignInfoServer,
  saveCampaignInfoServer,
  saveCampaignOriginsServer,
  saveCampaignQuestionnaireServer,
  saveCampaignSitesServer,
  saveCampaignStatusChangeServer,
  saveCustomTemplateServer,
  saveDefaultTextServer,
  saveExternalTrackingServer,
  saveInterviewQuestionsAsQuestionnaireServer,
  saveInterviewRecruiterCommentServer,
  saveJobClusterServer,
  saveJobConfigServer,
  saveJobInfoServer,
  saveJobInterviewServer,
  saveJobTemplateInterviewServer,
  saveJobTemplateServer,
  saveJobTemplateTagServer,
  saveOnboardTenantServer,
  saveOriginInfoServer,
  saveOriginServer,
  saveSiteInfoServer,
  saveSiteServer,
  saveSitesAndOriginsServer,
  saveTenantConfigServer,
  saveTenantInfoServer,
  selectAllAssessmentsBySearchServer,
  selectAllContactsBySearchServer,
  selectAllInterviewGuidsServer,
  selectAllRecipientsBySearchServer,
  sendBulkMessagesServer,
  sendChatMessageServer,
  sendTemplateChatMessageServer,
  sendTfaCodeserver,
  sendWabaSetupInviteServer,
  sendWebMessengerTextServer,
  setChatTranslationServer,
  startChatAfterJobSelectionServer,
  startOnboardTenantServer,
  stepMediaUploadServer,
  submitPrivacyConfirmationServer,
  subscribeToInterviewServer,
  tenantLogoUploadMediaServer,
  translateChatTextServer,
  translatePropTextServer,
  translateTextServer,
  updateApplicationPropertiesServer,
  updateAssessmentRunServer,
  updateCampaignInterviewServer,
  updateCampaignSettingsServer,
  updateCandidateGroupServer,
  updateCandidateLanguageServer,
  updateCandidateNameServer,
  updateCandidateSalutationServer,
  updateCandidateTagNameServer,
  updateCandidateTagsServer,
  updateClusterJobsServer,
  updateContactGroupServer,
  updateContactServer,
  updateDeleteAfterDateServer,
  updateGroupServer,
  updateInfoNoteServer,
  updateInterviewParamsServer,
  updateInterviewSitesServer,
  updateJobClusterOriginsServer,
  updateJobOriginsServer,
  updateMessageTextServer,
  updateMessagesRunServer,
  updateMessengerChannelServer,
  updateMultipleCandidatesGroupServer,
  updatePublishedPlatformServer,
  updateQuestionnaireServer,
  updateRemoteSystemServer,
  updateRoleServer,
  updateUserCredentialServer,
  updateUserNotificationsServer,
  updateUserPreferredChatLanguageServer,
  updateUserPreferredLanguageServer,
  updateUserServer,
  updateUserStatusServer,
  updateUserTfaServer,
  uploadContactsExcelServer,
  uploadStartMessageMediaForJobServer,
  uploadStartMessageMediaServer,
  validateSalesTokenServer,
  verifyTfaCodeserver,
  webMessengerUploadMediaServer,
  wipeOutTenantServer
} from './server/api-server';

export function clearJWT() {
  clearJWTServer();
}

export async function useServer(): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    let tenant: Tenant | null = tenantModule.tenantInfo || null;
    if (!tenant) {
      tenant = await tenantModule.loadTenantInfo();
    }
    if (tenant && tenant.readonly) {
      resolve(false);
    }
    resolve(true);
  });
}

export async function useServerLite(): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    let tenant: TenantLite | null = tenantModule.tenantInfoLite || null;
    if (!tenant) {
      tenant = await tenantModule.loadTenantInfoLite();
    }
    if (tenant && tenant.readonly) {
      resolve(false);
    }
    resolve(true);
  });
}

export async function fetchInterviewDetail(
  interviewGuid: string
): Promise<Interview> {
  return fetchInterviewDetailServer(interviewGuid);
}

export async function fetchAlternativeGroups(
  interviewGuid: string
): Promise<GroupsResponseDto> {
  return fetchAlternativeGroupsServer(interviewGuid);
}

export async function updateCandidateGroup(
  requestObj: GroupTransitionRequest
): Promise<GroupTransitionResponse> {
  return updateCandidateGroupServer(requestObj);
}

export async function fetchTenantInfo(): Promise<Tenant> {
  return fetchTenantInfoServer();
}

export async function fetchTenantInfoLite(
  languageCode?: string
): Promise<TenantLite> {
  return fetchTenantInfoLiteServer(languageCode);
}

export async function fetchTenantLoginInfo(): Promise<TenantLoginInfo> {
  return fetchTenantLoginInfoServer();
}

export async function fetchTenantMessageInfo(): Promise<TenantMessageInfo> {
  return fetchTenantMessageInfoServer();
}

export async function performLogin(loginRequest: LoginRequest): Promise<User> {
  return performLoginServer(loginRequest);
}

export async function submitPrivacyConfirmation(
  confirmRequest: PrivacyConfirmationRequest
): Promise<PrivacyConfirmationResponse> {
  return submitPrivacyConfirmationServer(confirmRequest);
}

export async function fetchInterviewRecruiterDetail(
  request: LoadInterviewDetailRequest
): Promise<RecruiterInterview> {
  if (await useServer()) {
    return fetchInterviewRecruiterDetailServer(request);
  } else {
    return fetchInterviewRecruiterDetailMock(
      request.interviewGuid,
      request.jobSlug
    );
  }
}

export async function fetchInterviewRecruiterComments(
  interviewGuid: string
): Promise<CommentResponse> {
  if (await useServer()) {
    return fetchInterviewRecruiterCommentsServer(interviewGuid);
  } else {
    return fetchInterviewRecruiterCommentsMock(interviewGuid);
  }
}

export async function saveInterviewRecruiterComment(
  commentRequest: CommentRequest
): Promise<any> {
  if (await useServer()) {
    return saveInterviewRecruiterCommentServer(commentRequest);
  } else {
    return saveInterviewRecruiterCommentMock(commentRequest);
  }
}

export async function fetchJobList(
  jobListRequest: JobListRequest
): Promise<JobListResponse> {
  if (await useServer()) {
    return fetchJobListServer(jobListRequest);
  } else {
    return fetchJobListMock(jobListRequest);
  }
}

export async function fetchJobDashboardDetails(
  requestObj: JobDashboardRequest
): Promise<JobDashboardResponse> {
  if (await useServer()) {
    return fetchJobDashboardDetailsServer(requestObj);
  } else {
    return fetchJobDashboardDetailsMock(requestObj);
  }
}

export async function doJobDashboardSearch(
  requestObj: JobDashboardSearchRequest
): Promise<JobDashboardSearchResponse> {
  if (await useServer()) {
    return doJobDashboardSearchServer(requestObj);
  } else {
    return await doJobDashboardSearchMock(requestObj);
  }
}

export async function doGetChatMessages(
  requestObj: ChatMessageRequest
): Promise<ChatMessageResponse> {
  return getChatMessagesServer(requestObj);
}

export async function doGetAllChatMessages(
  request: AllChatMessageRequestDto
): Promise<AllChatMessageResponseDto> {
  return getAllMessagesServer(request);
}

export async function doGetAllChatMessagesCount(): Promise<
  AllChatMessagescountResponse
> {
  return getAllChatMessagesCountServer();
}

export async function doConfirmReadChatMessage(
  requestObj: ChatMessageRequest
): Promise<any> {
  return confirmMessageReadServer(requestObj);
}

export async function doSendChatMessage(
  requestObj: SendChatMessageRequest
): Promise<string> {
  return sendChatMessageServer(requestObj);
}

export async function doSendTemplateChatMessage(
  requestObj: SendChatMessageRequest
): Promise<ChatMessage> {
  return sendTemplateChatMessageServer(requestObj);
}

export async function getShortUrlTarget(
  request: ShortUrlRequest
): Promise<ShortUrlEntity> {
  return getShortUrlTargetServer(request);
}

export async function isDirectDisclaimerForGuid(
  guid: string
): Promise<DirectDisclaimerResponseDto> {
  return isDirectDisclaimerForGuidServer(guid);
}

export async function isDirectDisclaimer(): Promise<Boolean> {
  return isDirectDisclaimerServer();
}

export async function doJobDashboardStatusChange(
  requestObj: JobDashboardStatusChangeRequest
): Promise<JobDashboardStatusChangeResponse> {
  if (await useServer()) {
    return doJobDashboardStatusChangeServer(requestObj);
  } else {
    return doJobDashboardStatusChangeMock(requestObj);
  }
}

export async function doStatusTransition(
  requestObj: TransitionStatusRequest
): Promise<RecruiterInterview> {
  if (await useServer()) {
    return doStatusTransitionServer(requestObj);
  } else {
    return doStatusTransitionMock(requestObj);
  }
}

export async function doLogAction(requestObj: ActionLogRequest): Promise<any> {
  return doLogActionServer(requestObj);
}

export async function getActionProtocol(
  interviewGuid: string
): Promise<InterviewActionProtocolResponse> {
  return fetchActionProtocolServer(interviewGuid);
}

export async function getInterviewAttachments(
  interviewGuid: string
): Promise<InterviewAttachmentResponse> {
  return fetchInterviewAttachmentsServer(interviewGuid);
}

export async function sendWebMessengerText(message: Message): Promise<Message> {
  return sendWebMessengerTextServer(message);
}

export async function initWebMessenger(
  initMessage: InitMessage
): Promise<InitMessage> {
  return initWebMessengerServer(initMessage);
}

export async function createSalesTestUser(
  requestObj: SalesUserRequest
): Promise<SalesUserResponse> {
  if (!(await useServer())) {
    return createSalesTestUserServer(requestObj);
  }

  throw new Error('NO_READ_ONLY_TENANT');
}

export async function initSalesSession(): Promise<any> {
  if (!(await useServer())) {
    return initSalesSessionServer();
  }

  throw new Error('NO_READ_ONLY_TENANT');
}

export async function webMessengerUploadMedia(
  formData: Object
): Promise<Message[]> {
  return webMessengerUploadMediaServer(formData);
}

export async function chatMessageUploadMedia(
  formData: Object
): Promise<ChatMessage> {
  return chatMessageUploadMediaServer(formData);
}

export async function stepMediaUpload(formData: Object): Promise<StepMedia> {
  return stepMediaUploadServer(formData);
}

export async function doChangePassword(
  changePasswordRequest: ChangePasswordRequest
): Promise<any> {
  if (await useServer()) {
    await doChangePasswordServer(changePasswordRequest);
  }
  return Promise.resolve({});
}

export async function doResetPassword(
  resetPasswordRequest: ResetPasswordRequest
): Promise<any> {
  if (await useServer()) {
    doPasswordResetServer(resetPasswordRequest);
  }
  return Promise.resolve({});
}

export async function doValidateNewPasswordToken(
  validateNewPasswordTokenRequest: NewPasswordRequest
): Promise<Boolean> {
  if (await useServer()) {
    return doValidateNewPasswordTokenServer(validateNewPasswordTokenRequest);
  }
  return Promise.resolve(false);
}

export async function doSaveNewPassword(
  newPasswordTokenRequest: NewPasswordRequest
): Promise<any> {
  if (await useServer()) {
    doChangePasswordByTokenServer(newPasswordTokenRequest);
  }
  return Promise.resolve({});
}

export async function fetchChangeableSites(
  requestObj: SiteSelectionRequest
): Promise<SiteSelectionResponse> {
  return fetchChangeableSitesServer(requestObj);
}

export async function fetchSites(
  requestObj: SiteSelectionRequest
): Promise<SiteSelectionResponse> {
  return fetchSitesServer(requestObj);
}

export async function saveSiteInfo(requestObj: Site): Promise<Site> {
  if (await useServer()) {
    return saveSiteInfoServer(requestObj);
  }
  return Promise.resolve({ slug: '', country: 'DE' });
}

export async function saveOriginInfo(requestObj: Origin): Promise<Origin> {
  if (await useServer()) {
    return saveOriginInfoServer(requestObj);
  }
  return Promise.resolve({
    slug: '',
    description: '',
    selectionDisabled: false,
    originForViber: false
  });
}

export async function saveJobInfo(
  requestObj: JobInfoRequest
): Promise<JobInfoResponse> {
  if (await useServer()) {
    return saveJobInfoServer(requestObj);
  }
  return Promise.resolve({
    slug: '',
    renderWizardButton: false,
    jobCategory: '',
    jobCategories: [],
    showExternalJobId: false,
    isReadOnlyDefaultJob: false,
    internalNotes: '',
    defaultJob: false,
    inActiveStatus: false,
    jobLanguage: '',
    viberWelcomeMessage: null
  });
}

export async function saveSitesAndOrigins(
  requestObj: JobInfoRequest
): Promise<any> {
  if (await useServer()) {
    return saveSitesAndOriginsServer(requestObj);
  }
  return Promise.resolve({ slug: '', renderWizardButton: false });
}

export async function fetchJobInfo(
  requestObj: IJobInfoRequest
): Promise<JobInfoResponse> {
  return fetchJobInfoServer(requestObj);
}

export async function fetchJobCategories(): Promise<JobCategoryResponse> {
  return fetchJobCategoriesServer();
}

export async function fetchCountriesOption(): Promise<CountriesOptionResponse> {
  return fetchCountriesOptionServer();
}

export async function fetchMoreCountriesOption(): Promise<
  CountriesOptionResponse
> {
  return fetchMoreCountriesOptionServer();
}

export async function fetchLanguagesOption(): Promise<CountriesOptionResponse> {
  return fetchLanguageOptionServer();
}

export async function fetchLanguagesOptionTemplate(
  requestObj: LoadLanguageTemplatesRequest
): Promise<CountriesOptionResponse> {
  return fetchLanguageOptionTemplateServer(requestObj);
}

export async function detectLanguage(
  requestObj: TranslationPropTextDto
): Promise<string> {
  return detectLanguageServer(requestObj);
}

export async function validateSalesToken(salesToken: string): Promise<boolean> {
  return validateSalesTokenServer(salesToken);
}

export async function tenantLogoUploadMedia(formData: Object): Promise<string> {
  return tenantLogoUploadMediaServer(formData);
}

export async function initWhatsAppPage(
  languageCode: string
): Promise<PrivacyConfTextResponseDto> {
  return initWhatsAppPageServer(languageCode);
}

export async function doJobStatusChange(
  requestObj: JobStatusChangeRequest
): Promise<JobStatusChangeResponse> {
  if (await useServer()) {
    return doJobStatusChangeServer(requestObj);
  }
  return Promise.resolve({
    slug: requestObj.slug,
    activeStatus: 'ACTIVE' == requestObj.activeStatus ? 'INACTIVE' : 'ACTIVE'
  });
}

export async function doRejectInterviews(
  requestObj: RejectInterviewRequest
): Promise<any> {
  if (await useServer()) {
    return doRejectInterviewsServer(requestObj);
  }
  return Promise.resolve({});
}

export async function saveJobInterview(
  requestObj: JAWInterviewRequest
): Promise<any> {
  if (await useServer()) {
    return saveJobInterviewServer(requestObj);
  }
  return Promise.resolve({});
}

export async function findJobInterview(
  requestObj: IJAWInterviewRequest
): Promise<JAWInterviewRequest> {
  return findJobInterviewServer(requestObj);
}

export async function findPropKeys(
  requestObj: JAWConfigRequest
): Promise<JAWConfig> {
  return findPropKeysServer(requestObj);
}

export async function findJobOrigins(jobSlug: string): Promise<Origin[]> {
  return findJobOriginsServer(jobSlug);
}

export async function findJobOriginSelections(
  jobSlug: string
): Promise<OriginSelectionResponse> {
  return findJobOriginSelectionsServer(jobSlug);
}

export async function findAppProps(jobSlug: string): Promise<JAWConfig> {
  return findAppPropsServer(jobSlug);
}

export async function saveJobConfig(requestObj: JAWConfig): Promise<any> {
  return saveJobConfigServer(requestObj);
}

export async function doGetReportTopApplicants(): Promise<
  ReportTopApplicantResponse
> {
  return doGetReportTopApplicantsServer();
}

export async function doGetReportNoOfApplicants(): Promise<ChartDataDto> {
  if (await useServer()) {
    return doGetReportNoOfApplicantsServer();
  }
  const demoData: ChartDataDto = {
    datasets: [
      {
        data: [
          '8',
          '9',
          '12',
          '15',
          '11',
          '15',
          '22',
          '19',
          '15',
          '23',
          '20',
          '21',
          '23',
          '20',
          '16',
          '19',
          '23',
          '26',
          '25',
          '26',
          '31',
          '28',
          '33',
          '33',
          '36',
          '35',
          '30',
          '38',
          '38',
          '42'
        ],
        borderColor: '',
        fill: false,
        borderWidth: 0,
        pointRadius: 0,
        backgroundColor: '',
        label: ''
      }
    ],
    labels: []
  };
  const apiResponse = await doGetReportNoOfApplicantsServer();
  apiResponse.datasets = demoData.datasets;

  return apiResponse;
}
export async function fetchJobUrls(
  requestObj: JobUrlsRequest
): Promise<JobUrlsResponse> {
  if (await useServer()) {
    return fetchJobUrlsServer(requestObj);
  }
  return Promise.resolve({
    groupedJobUrls: [],
    renderEditJob: false,
    slug: requestObj.slug,
    languageCode: requestObj.languageCode,
    jobTitle: ''
  });
}
export async function doGetReportInitialApplicantsPerJob(): Promise<
  ChartDataDto
> {
  if (await useServer()) {
    return doGetReportInitialApplicantsPerJobServer();
  }

  const result: ChartDataDto = await doGetReportInitialApplicantsPerJobServer();
  const newData: string[] = [];
  result.labels.forEach(element => {
    newData.push(String(randomIntFromInterval(90, 160)));
  });

  return Promise.resolve({
    labels: result.labels,
    datasets: [
      {
        data: newData,
        borderColor: '',
        fill: false,
        borderWidth: 0,
        pointRadius: 0,
        backgroundColor: '',
        label: ''
      }
    ]
  });
}

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function doGetReportApplicantsFunnel(): Promise<FunnelDataDto> {
  if (await useServer()) {
    return doGetReportApplicantsFunnelServer();
  }
  return Promise.resolve({
    allNo: '1854',
    interestingNo: '404',
    doneNo: '157',
    interestingPercent: '',
    donePercent: ''
  });
}

export async function doShareInterview(
  requestObj: ShareInterviewRequest
): Promise<any> {
  if (await useServer()) {
    return doShareInterviewServer(requestObj);
  }
  return Promise.resolve({});
}

export async function doShareExists(shareGuid: string): Promise<boolean> {
  if (await useServerLite()) {
    return doShareExistsServer(shareGuid);
  }
  return Promise.resolve(false);
}

export async function getSharedInterview(
  requestObj: GetShareInterviewRequest
): Promise<GetShareInterviewResponse> {
  return getSharedInterviewServer(requestObj);
}

export async function getSharedInterviewContent(
  requestObj: GetShareInterviewContent
): Promise<ShareInterview> {
  return getSharedInterviewContentServer(requestObj);
}
export async function getSharedInterviewComments(
  requestObj: GetShareInterviewRequest
): Promise<CommentResponse> {
  return getSharedInterviewCommentsServer(requestObj);
}

export async function doAddExternalComment(
  requestObj: ExternalCommentRequest
): Promise<any> {
  return doAddExternalCommentServer(requestObj);
}

export async function getTenantInfo(): Promise<TenantInfo> {
  return getTenantInfoServer();
}

export async function saveTenantInfo(
  tenantInfo: TenantInfo
): Promise<TenantInfo> {
  return saveTenantInfoServer(tenantInfo);
}

export async function deleteSite(site: Site): Promise<any> {
  return deleteSiteServer(site);
}

export async function deleteOrigin(origin: Origin): Promise<any> {
  return deleteOriginServer(origin);
}

export async function saveOrigin(origin: Origin): Promise<any> {
  return saveOriginServer(origin);
}

export async function saveSite(requestObj: CreateSiteRequest): Promise<any> {
  return saveSiteServer(requestObj);
}

export async function getSites(): Promise<Site[]> {
  return getSitesServer();
}

export async function getOrigins(): Promise<Origin[]> {
  return getOriginsServer();
}

export async function getSiteSelection(
  requestObj: SiteSelectionRequest
): Promise<SiteSelectionResponse> {
  return getSiteSelectionServer(requestObj);
}

export async function getOriginSelections(
  jobSlug: string
): Promise<OriginSelectionResponse> {
  return getOriginSelectionsServer(jobSlug);
}

export async function fetchAllJobs(
  jobListRequest: JobListRequest
): Promise<JobListResponse> {
  if (await useServer()) {
    return fetchAllJobsServer(jobListRequest);
  } else {
    return fetchJobListMock(jobListRequest);
  }
}

export async function copyJob(copyJobRequest: CopyJobRequest): Promise<any> {
  return copyJobServer(copyJobRequest);
}
export async function getRootGroups(): Promise<GroupsResponseDto> {
  return getRootGroupsServer();
}

export async function createNewGroup(requestObj: GroupDto): Promise<GroupDto> {
  return createNewGroupServer(requestObj);
}
export async function updateGroup(requestObj: GroupDto): Promise<GroupDto> {
  return updateGroupServer(requestObj);
}
export async function deleteGroup(requestObj: GroupDto): Promise<GroupDto> {
  return deleteGroupServer(requestObj);
}

export async function getChildGroups(parentSlug: string): Promise<GroupDto[]> {
  return getChildGroupsServer(parentSlug);
}

export async function getGroupsUser(
  groupSlug: string
): Promise<SelectableUser[]> {
  return getGroupsUserServer(groupSlug);
}

export async function getSelectableUser(
  groupSlug: string
): Promise<SelectableUser[]> {
  return getSelectableUserServer(groupSlug);
}

export async function assignUser(
  groupSlug: AssignUserRequestDto
): Promise<any> {
  return assignUserServer(groupSlug);
}

export async function getTenantAdminUsers(): Promise<User[]> {
  return getTenantAdminUsersServer();
}

export async function updateUser(requestObj: User): Promise<User> {
  return updateUserServer(requestObj);
}

export async function deleteUser(requestObj: User): Promise<any> {
  return deleteUserServer(requestObj);
}

export async function updateRole(requestObj: Role): Promise<Role> {
  return updateRoleServer(requestObj);
}

export async function getRole(requestObj: string): Promise<Role> {
  return getRoleServer(requestObj);
}

export async function getSelectableSites(
  requestObj: string
): Promise<SelectableSite[]> {
  return getSelectableSitesServer(requestObj);
}

export async function getSelectableJobs(
  requestObj: string
): Promise<SelectableJob[]> {
  return getSelectableJobsServer(requestObj);
}
export async function isCred(requestObj: any): Promise<boolean | null> {
  return isCredServer(requestObj);
}

export async function getAssignableGroupJobs(
  requestObj: string
): Promise<SelectableJob[]> {
  return getAssignableGroupJobsServer(requestObj);
}
export async function getSharedEmails(): Promise<any> {
  return getSharedEmailsServer();
}

export async function getAllJobClusters(
  languageCode: string
): Promise<JobClusterItem[]> {
  return getAllJobClustersServer(languageCode);
}

export async function saveJobCluster(
  requestObj: SaveJobClusterRequest
): Promise<JobClusterItem> {
  return saveJobClusterServer(requestObj);
}

export async function getApplicantSearchResult(
  requestObj: ApplicantSearchRequest
): Promise<ApplicantSearchResult> {
  return getApplicantSearchResultServer(requestObj);
}

export async function getApplicantSearchSelectableValues(
  requestObj: ExternalJobSetting
): Promise<ApplicantSearchSelectableValues> {
  return getApplicantSearchSelectableValuesServer(requestObj);
}

export async function getApplicantSearchExcelExport(
  requestObj: ApplicantSearchRequest
): Promise<any> {
  return getApplicantSearchExcelExportServer(requestObj);
}

export async function bookmarkSite(requestObj: string): Promise<any> {
  return bookmarkSiteServer(requestObj);
}

export async function activateJobSite(
  requestObj: ActivateJobSiteRequest
): Promise<any> {
  return activateJobSiteServer(requestObj);
}

export async function deactivateJobSite(
  requestObj: ActivateJobSiteRequest
): Promise<any> {
  return deactivateJobSiteServer(requestObj);
}

export async function updatePublishedPlatform(
  requestObj: PublishedPlatformRequest
): Promise<any> {
  return updatePublishedPlatformServer(requestObj);
}

export async function updateUserStatus(
  requestObj: UpdateUserStatusRequest
): Promise<User> {
  if (await useServer()) {
    return updateUserStatusServer(requestObj);
  } else {
    return updateUserStatusMock(requestObj);
  }
}

export async function directAssignUser(
  requestDto: DirectAssignUserGroup
): Promise<any> {
  return directAssignUserServer(requestDto);
}

export async function getRecentApplications(
  requestDto: string
): Promise<RecentApplication[]> {
  return getRecentApplicationsServer(requestDto);
}

export async function doGetReportApplicantsPerJob(): Promise<ChartDataDto> {
  return doGetReportApplicantsPerJobServer();
}

export async function updateCandidateName(
  requestObj: UpdateCandidateName
): Promise<any> {
  return updateCandidateNameServer(requestObj);
}

export async function getApplicationProperties(): Promise<
  JAWApplicationConfig[]
> {
  return getApplicationPropertiesServer();
}

export async function updateApplicationProperties(
  requestObj: JAWApplicationConfig[]
): Promise<any> {
  return updateApplicationPropertiesServer(requestObj);
}

export async function isCreateTenantIdentifierValid(
  requestObj: string
): Promise<Boolean> {
  return isCreateTenantIdentifierValidServer(requestObj);
}

export async function createNewTenant(requestObj: Object): Promise<any> {
  return createNewTenantServer(requestObj);
}

export async function getJobTemplates(): Promise<JobTemplateResponseDto> {
  return getJobTemplatesServer();
}

export async function getJobTemplate(
  slug: string
): Promise<JobTemplateListDto> {
  return getJobTemplateServer(slug);
}

export async function saveJobTemplate(
  requestObj: JobTemplateListDto
): Promise<JobTemplateListDto> {
  return saveJobTemplateServer(requestObj);
}

export async function getJobTemplateQuestions(
  slug: string
): Promise<JAWInterviewRequest> {
  return getJobTemplateQuestionsServer(slug);
}

export async function saveJobTemplateInterview(
  requestObj: JobTemplateInterviewRequest
): Promise<JAWInterviewRequest> {
  return saveJobTemplateInterviewServer(requestObj);
}

export async function getJobTemplateTags(
  slug: string
): Promise<JobTemplateTag[]> {
  return getJobTemplateTagsServer(slug);
}

export async function saveJobTemplateTag(
  requestObj: JobTemplateTagRequest
): Promise<JobTemplateTag> {
  return saveJobTemplateTagServer(requestObj);
}

export async function getMessageTemplates(): Promise<MessageTemplate[]> {
  return getMessageTemplatesServer();
}

export async function getDisclaimerTarget(
  request: ShortUrlRequest
): Promise<DisclaimerTargetResponse> {
  return getDisclaimerTargetServer(request);
}

export async function applyDisclaimer(
  request: ShortUrlRequest
): Promise<DisclaimerTargetResponse> {
  return applyDisclaimerServer(request);
}

export async function getSelectableJobTemplates(): Promise<
  SelectableJobTemplate[]
> {
  return getSelectableJobTemplatesServer();
}

export async function updateUserNotifications(requestObj: User): Promise<User> {
  return updateUserNotificationsServer(requestObj);
}

export async function createNewJobSite(
  requestObj: CreateJobSiteRequest
): Promise<any> {
  return createNewJobSiteServer(requestObj);
}

export async function setChatTranslation(
  requestObj: SetChatTranslationRequest
): Promise<any> {
  return setChatTranslationServer(requestObj);
}

export async function fetchFunnelChartReports(
  funnelreportRequest: FunnelReportRequest
): Promise<FunnelReportResponse> {
  return fetchFunnelReportsServer(funnelreportRequest);
}

export async function fetchStackChartReports(
  funnelreportRequest: FunnelReportRequest
): Promise<StackChartReportResponse> {
  return fetchStackChartReportsServer(funnelreportRequest);
}

export async function fetchJobsFilterResults(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return fetchJobsFilterResultsServer(searchRequest);
}

export async function fetchTenantFilterResults(): Promise<
  ReportsFilterResponse[]
> {
  return fetchTenantFilterResultsServer();
}

export async function fetchOriginFilterResults(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return fetchOriginFilterResultsServer(searchRequest);
}

export async function fetchRemoteSystemFilterResults(): Promise<
  ReportsFilterResponse[]
> {
  return fetchRemoteSystemFilterResultsServer();
}

export async function fetchGroups(
  tenantId: number | null
): Promise<ReportsFilterResponse[]> {
  return fetchGroupsServer(tenantId);
}

export async function getMessageCenterContact(
  interviewGuid: string
): Promise<MessageCenterContact> {
  return getMessageCenterContactServer(interviewGuid);
}

export async function updateUserCredential(
  request: UpdateCredentialRequest
): Promise<any> {
  return updateUserCredentialServer(request);
}

export async function createNewContact(request: Contact): Promise<string> {
  return createNewContactServer(request);
}

export async function uploadContactsExcel(formData: FormData): Promise<any> {
  return uploadContactsExcelServer(formData);
}

export async function removeContact(request: string): Promise<any> {
  return removeContactServer(request);
}

export async function updateContact(request: Contact): Promise<any> {
  return updateContactServer(request);
}

export async function fetchReportsExcelExport(
  request: FunnelReportRequest
): Promise<any> {
  return fetchReportsExcelExportServer(request);
}

export async function fetchJobsMainCredentials(): Promise<JobsMainCredentials> {
  return fetchJobMainsCredentialsServer();
}

export async function archiveJob(jobSlug: string): Promise<any> {
  return archiveJobServer(jobSlug);
}

export async function getQuestionnaires(
  request: QuestionnaireSearchRequestDto
): Promise<QuestionnaireSearchResponseDto> {
  return getQuestionnairesServer(request);
}

export async function updateQuestionnaire(
  request: QuestionnaireDetailDto
): Promise<QuestionnaireDetailDto> {
  return updateQuestionnaireServer(request);
}

export async function getQuestionnaire(
  request: string
): Promise<QuestionnaireDetailDto> {
  return getQuestionnaireServer(request);
}

export async function getAssessmentRuns(
  request: AssessmentSearchRequestDto
): Promise<AssessmentSearchResponseDto> {
  return getAssessmentRunsServer(request);
}

export async function getActiveQuestionnaires(
  type: QuestionnaireType
): Promise<QuestionnaireSearchItemDto[]> {
  return getActiveQuestionnairesServer(type);
}

export async function getMessageCenterContacts(
  request: MessageCenterContactSearchRequestDto
): Promise<MessageCenterContactSearchResponseDto> {
  return getMessageCenterContactsServer(request);
}

export async function getAllMessageCenterContacts(
  request: AllChatMessageRequestDto
): Promise<AllChatMessageResponseDto> {
  return getAllMessageCenterContactsServer(request);
}

export async function updateAssessmentRun(
  request: AssessmentRunDto
): Promise<AssessmentRunDto> {
  return updateAssessmentRunServer(request);
}

export async function updateMessagesRun(
  request: string[]
): Promise<ChatMessage[]> {
  return updateMessagesRunServer(request);
}

export async function getAssessmentRunDetail(
  request: AssessmentDetailRequestDto
): Promise<AssessmentRunDetailDto> {
  return getAssessmentRunDetailServer(request);
}

export async function getRecruitMeMessages(
  request: string
): Promise<RecruitMeMessagesResponse> {
  return getRecruitMeMessagesServer(request);
}

export async function cancelAssessment(
  request: string
): Promise<MessageCenterContact> {
  return cancelAssessmentServer(request);
}

export async function cancelAssessmentRun(request: string): Promise<any> {
  return cancelAssessmentRunServer(request);
}

export async function getAssessmentRun(
  assessmentRunSlug: string
): Promise<AssessmentRunDto> {
  return getAssessmentRunServer(assessmentRunSlug);
}

export async function sendBulkMessages(
  request: BulkMessageRequestDto
): Promise<any> {
  return sendBulkMessagesServer(request);
}

export async function getAdditionalProfileInfos(
  request: string
): Promise<AdditionalProfileInfoDto> {
  return getAdditionalProfileInfosServer(request);
}

export async function getCandidateTags(): Promise<CandidateTagDto[]> {
  return getCandidateTagsServer();
}

export async function getCandidateTagsWithGroup(): Promise<GroupCandidateTagDto[]> {
  return getCandidateTagsWithGroupServer();
}

export async function getMessengerChannel(): Promise<boolean> {
  return hasMessengerChannelServer();
}

export async function updateCandidateTags(
  requestObj: CandidateTagsUpdateRequest
): Promise<any> {
  return updateCandidateTagsServer(requestObj);
}

export async function createCandidateTag(
  request: CandidateTagCreateRequest
): Promise<CandidateTagDto[]> {
  return createCandidateTagServer(request);
}

export async function createCandidateTagAssignGroup(
  request: CandidateTagCreateAssignGroupRequest
): Promise<CandidateTagDto[]> {
  return createCandidateTagAssignGroupServer(request);
}

export async function selectAllContactsBySearch(request: SelectAllContactsAndTagsRequestDto
): Promise<string[]> {
  return selectAllContactsBySearchServer(request);
}

export async function copyQuestionnaire(request: any): Promise<any> {
  return copyQuestionnaireServer(request);
}

export async function assignTagsToMultipleAssessments(
  request: GroupTagAssessmentsRequestDto
): Promise<any> {
  return assignTagsToMultipleAssessmentsServer(request);
}

export async function selectAllAssessmentsBySearch(
  request: SelectAllAssessmentsRequestDto
): Promise<string[]> {
  return selectAllAssessmentsBySearchServer(request);
}

export async function fetchNewContactGroups(): Promise<ILabelValue[]> {
  return fetchNewContactGroupsServer();
}

export async function updateUserPreferredLanguage(
  userPreferredLanguage: string
): Promise<any> {
  return updateUserPreferredLanguageServer(userPreferredLanguage);
}

export async function getUserPreferredLanguage(): Promise<User> {
  return getUserPreferredLanguageServer();
}

export async function getDashboardChartReport(): Promise<
  StackChartReportResponse
> {
  return getDashboardChartReportServer();
}

export async function archiveJobCluster(jobClusterSlug: string): Promise<any> {
  return archiveJobClusterServer(jobClusterSlug);
}

export async function archiveAssessmentRun(
  assessmentRunSlug: string
): Promise<any> {
  return archiveAssessmentRunServer(assessmentRunSlug);
}

export async function archiveQuestionnaire(
  questionnaireSlug: string
): Promise<any> {
  return archiveQuestionnaireServer(questionnaireSlug);
}

export async function fetchJobClustersFilterResults(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return fetchJobClustersFilterResultsServer(searchRequest);
}

export async function fetchUtmTagsFilterResults(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return fetchUtmTagsFilterResultsServer(searchRequest);
}

export async function getJobCluster(
  request: JobClusterRequest
): Promise<JobClusterItem> {
  return getJobClusterServer(request);
}

export async function getBulkMessages(
  request: BulkMessageSearchRequestDto
): Promise<BulkMessageSearchResponseDto> {
  return getBulkMessagesServer(request);
}

export async function getJobUrlsByGroup(
  request: JobUrlByGroupRequestDto
): Promise<JobUrl[]> {
  return getJobUrlsByGroupServer(request);
}

export async function getBulkMessageRecipients(
  request: BulkMessageRecipientSearchRequestDto
): Promise<BulkMessageRecipientResponseDto> {
  return getBulkMessageRecipientsServer(request);
}

export async function updateJobOrigins(
  request: JobOriginRequestDto
): Promise<any> {
  return updateJobOriginsServer(request);
}

export async function getMediaMetaData(mediaGuid: string): Promise<any> {
  return getMediaMetaDataServer(mediaGuid);
}

export async function selectAllRecipientsBySearch(
  request: SelectAllRecipientsRequestDto
): Promise<string[]> {
  return selectAllRecipientsBySearchServer(request);
}

export async function assignTagsToMultipleRecipients(
  request: GroupTagRecipientsRequestDto
): Promise<any> {
  return assignTagsToMultipleRecipientsServer(request);
}

export async function deleteBulkMessageRecipient(
  recipientSlug: string
): Promise<any> {
  return deleteBulkMessageRecipientServer(recipientSlug);
}

export async function getNotificationInfo(
  interviewGuid: string,
  slug: string
): Promise<NotificationInfoDto> {
  return getNotificationInfoServer(interviewGuid, slug);
}

export async function addParticipants(
  requestObj: AddParticipantsDto
): Promise<any> {
  return addParticipantsServer(requestObj);
}

export async function addAssessmentGroups(
  requestObj: AddAssessmentGroupDto
): Promise<any> {
  return addAssessmentGroupsServer(requestObj);
}

export async function getAssessmentGroups(): Promise<FilterResponseDto[]> {
  return getAssessmentUserGroupsServer();
}

export async function updateContactGroup(
  requestObj: UpdateContactGroupDto
): Promise<any> {
  return updateContactGroupServer(requestObj);
}

export async function exportAssessments(
  assessmentrunSlug: string
): Promise<any> {
  return exportAssessmentsServer(assessmentrunSlug);
}

export async function updateDeleteAfterDate(
  requestObj: DleteAfterDto
): Promise<any> {
  return updateDeleteAfterDateServer(requestObj);
}

export async function permanentDeleteApplicant(
  requestObj: DleteAfterDto
): Promise<any> {
  return permanentDeleteApplicantServer(requestObj);
}

export async function updateInterviewParams(
  requestObj: InterviewParamsDto
): Promise<any> {
  return updateInterviewParamsServer(requestObj);
}

export async function updateCandidateSalutation(
  requestObj: CandidateSalutationDto
): Promise<any> {
  return updateCandidateSalutationServer(requestObj);
}

export async function wipeOutTenant(
  requestObj: WipeOutAuthCredentialsDto
): Promise<any> {
  return wipeOutTenantServer(requestObj);
}

export async function deactivateTenant(
  requestObj: DeactivateTenantDto
): Promise<any> {
  return deactivateTenantServer(requestObj);
}

export async function updateCandidateLanguage(
  requestObj: CandidateLanguageDto
): Promise<any> {
  return updateCandidateLanguageServer(requestObj);
}

export async function assignTagsToMultipleCandidates(
  requestObj: GroupTagCandidatesRequestDto
): Promise<any> {
  return assignTagsToMultiplecandidatesServer(requestObj);
}

export async function selectAllInterviewGuids(
  requestObj: ApplicantSearchRequest
): Promise<string[]> {
  return selectAllInterviewGuidsServer(requestObj);
}

export async function loadtemplates(
  requestObj: LoadTemplatesRequest
): Promise<ChannelTemplateMessageDto[]> {
  return loadtemplatesServer(requestObj);
}

export async function loadtemplateCandidate(
  requestObj: LoadTemplatesRequest
): Promise<ChannelTemplateMessageDto[]> {
  return loadtemplateCandidateServer(requestObj);
}

export async function loadRemoteSystemParams(
  remoteSystemType: string
): Promise<ILabelValue[]> {
  return loadRemoteSystemParamsServer(remoteSystemType);
}

export async function updateCandidateTagName(
  requestObj: TagMangementDto
): Promise<any> {
  return updateCandidateTagNameServer(requestObj);
}

export async function deleteCandidateTagName(
  requestObj: TagMangementDto
): Promise<any> {
  return deleteCandidateTagNameServer(requestObj);
}

export async function getTenantGroups(): Promise<any> {
  return getTenantGroupsServer();
}
export async function getUserGroupsByRole(): Promise<any> {
  return getRoleBasedGroupsServer();
}

export async function getUserGroups(): Promise<any> {
  return getUserGroupsServer();
}

export async function getJobTemplateGroups(): Promise<StepTemplateGroup[]> {
  return getJobTemplateGroupsServer();
}

export async function getContactsImportHistory(
  searchRequest: ContactsImportSearchRequest
): Promise<ContactsImportResultDto> {
  return getContactsImportHistoryServer(searchRequest);
}

export async function deleteContactsImport(slug: string) {
  return deleteContactsImportServer(slug);
}

export async function updateClusterJobs(requestObj: ClusterJobsUpdateDto) {
  return updateClusterJobsServer(requestObj);
}

export async function multipleStatusTransition(
  requestObj: TransitionStatusRequest[]
) {
  return multipleStatusTransitionServer(requestObj);
}

export async function fetchAllAlternativeGroups(jobSlug: string) {
  return fetchAllAlternativeGroupsServer(jobSlug);
}

export async function updateMultipleCandidatesGroup(
  requestObj: MultipleGroupTransitionRequest
) {
  return updateMultipleCandidatesGroupServer(requestObj);
}

export async function getConversationReports() {
  return getConversationReportsServer();
}

export async function getSingleContact(interviewGuid: string) {
  return getSingleContactServer(interviewGuid);
}

export async function getRemoteSystem() {
  return getRemoteSystemServer();
}

export async function getRemoteSystemTypes() {
  return getRemoteSystemTypesServer();
}

export async function updateRemoteSystem(requestObj: RemoteSystemRequestDto) {
  return updateRemoteSystemServer(requestObj);
}

export async function getLanguageReport(requestObj: FunnelReportRequest) {
  return getLanguageReportServer(requestObj);
}

export async function getJobClusterUrlsByGroup(
  request: JobClusterUrlByGroupRequestDto
): Promise<JobClusterUrlItem[]> {
  return getJobClusterUrlsByGroupServer(request);
}

export async function updateJobClusterOrigins(
  requestObj: JobClusterOriginsRequestDto
) {
  return updateJobClusterOriginsServer(requestObj);
}

export async function getInfoNote() {
  return getInfoNoteServer();
}

export async function updateInfoNote() {
  return updateInfoNoteServer();
}

export async function getCampaignSettings(jobSlug: string) {
  return getCampaignSettingsServer(jobSlug);
}

export async function saveCampaignInfo(requestObj: CampaignInfoRequestDto) {
  return saveCampaignInfoServer(requestObj);
}

export async function getCampaigns(requestObj: CampaignRequestDto) {
  return getCampaignsServer(requestObj);
}

export async function updateCampaignSettings(requestObj: JAWConfig) {
  return updateCampaignSettingsServer(requestObj);
}

export async function saveCamaignSites(requestObj: JobInfoRequest) {
  return saveCampaignSitesServer(requestObj);
}

export async function saveCampaignOrigins(requestObj: JobInfoRequest) {
  return saveCampaignOriginsServer(requestObj);
}

export async function getCampaignInfo(campaignSlug: string) {
  return getCampaignInfoServer(campaignSlug);
}

export async function saveCampaignQuestionnaire(
  requestObj: CampaignQuestionnaireRequestDto
) {
  return saveCampaignQuestionnaireServer(requestObj);
}

export async function getCampaignInterview(campaignSlug: string) {
  return getCampaignInterviewServer(campaignSlug);
}

export async function updateCampaignInterview(requestObj: JAWInterviewRequest) {
  return updateCampaignInterviewServer(requestObj);
}

export async function getCampaignTemplateGroups() {
  return getCampaignTemplateGroupsServer();
}

export async function saveCampaignStatusChange(
  requestObj: CampaignStatusChangeRequest
) {
  return saveCampaignStatusChangeServer(requestObj);
}

export async function saveInterviewQuestionsAsQuestionnaire(
  requestObj: CampaignQuestionnaireTemplateDto
) {
  return saveInterviewQuestionsAsQuestionnaireServer(requestObj);
}
export async function getOriginReport(requestObj: FunnelReportRequest) {
  return getOriginReportServer(requestObj);
}

export async function getExternalTrackingTypes() {
  return getExternalTrackingTypesServer();
}

export async function loadExternalTrackingParams(
  externalTrackingType: string
): Promise<ILabelValue[]> {
  return loadExternalTrackingParamsServer(externalTrackingType);
}

export async function getTrackingEventTypes() {
  return getTrackingEventTypesServer();
}

export async function getProductTypes() {
  return getProductTypesServer();
}

export async function getExternalTracking() {
  return getExternalTrackingServer();
}

export async function saveExternalTracking(input: ExternalTrackingDto) {
  return saveExternalTrackingServer(input);
}

export async function updateMessengerChannel(input: MessengerChannelDto) {
  return updateMessengerChannelServer(input);
}

export async function archiveMessengerChannel(input: MessengerChannelDto) {
  return archiveMessengerChannelServer(input);
}

export async function deleteActionProtocolComment(commentSlug: string) {
  return deleteActionProtocolCommentServer(commentSlug);
}

export async function getCandidateAgeReport(requestObj: FunnelReportRequest) {
  return getCandidateAgeReportServer(requestObj);
}

export async function getExternalJobInfo(tenantIds: number[]) {
  return fetchExternalJobInfosServer(tenantIds);
}

export async function fetchPostingJobIds(tenantIds: number[]) {
  return fetchPostingJobIdsServer(tenantIds);
}

export async function fetchPostingJobTitles(tenantIds: number[]) {
  return fetchPostingJobTitlesServer(tenantIds);
}

export async function fetchExternalCompanyNames(tenantIds: number[]) {
  return fetchExternalCompanyNamesServer(tenantIds);
}

export async function fetchJobPostingSites(): Promise<JobPostingSitesCountDto> {
  return fetchJobPostingSiteServer();
}

export async function updateInterviewSites(
  requestObj: string
): Promise<UpdateInterviewSitesResponseDto> {
  return updateInterviewSitesServer(requestObj);
}

export async function updateUserPreferredChatLanguage(
  userPreferredChatLanguage: string
): Promise<any> {
  return updateUserPreferredChatLanguageServer(userPreferredChatLanguage);
}

export async function getUserPreferredChatLanguage(): Promise<string> {
  return getUserPreferredChatLanguageServer();
}

export async function getMessage(slug: string): Promise<ChatMessage> {
  return getMessageServer(slug);
}

export async function getYesText(
  userPreferredChatLanguage: string
): Promise<string> {
  return getYesTextServer(userPreferredChatLanguage);
}

export async function copyJobTemplate(
  jobTemplateSlug: string
): Promise<CopyJobTemplateResult> {
  return copyJobTemplateServer(jobTemplateSlug);
}

export async function doUpdateUserTfa(requestObj: User): Promise<User> {
  return updateUserTfaServer(requestObj);
}

export async function doSubscribeToInterview(
  requestObj: UserSubscribedInterviewDto
): Promise<any> {
  return subscribeToInterviewServer(requestObj);
}

export async function doGetOnboardTenantByDealId(
  dealId: string
): Promise<OnboardNewTenantResponse> {
  return getOnboardTenantByDealIdServer(dealId);
}

export async function doGetOnboardTenantByContactId(
  contactId: string
): Promise<OnboardNewTenantResponse> {
  return getOnboardTenantByContactIdServer(contactId);
}

export async function doSaveOnboardTenant(
  requestObj: OnboardNewTenantRequest
): Promise<any> {
  return saveOnboardTenantServer(requestObj);
}

export async function doResetOnboardTenant(
  dealId: string
): Promise<OnboardNewTenantResponse> {
  return resetOnboardTenantServer(dealId);
}

export async function doFinalizeOnboardTenant(
  dealId: string
): Promise<OnboardNewTenantResponse> {
  return finalizeOnboardTenantServer(dealId);
}

export async function doSendWabaSetupInvite(
  requestObj: StartWabaSetupRequestDto
): Promise<any> {
  return sendWabaSetupInviteServer(requestObj);
}

export async function doStartOnboardTenant(dealId: string): Promise<any> {
  return startOnboardTenantServer(dealId);
}

export async function doGetDefaultJobDescription(
  languageCode: string
): Promise<DefaultJobDescriptionDto> {
  return getDefaultJobDescriptionServer(languageCode);
}

export async function doSendTFACode(): Promise<any> {
  return sendTfaCodeserver();
}

export async function doVerifyTFACode(
  tfaCode: string
): Promise<TFAVerificationDto> {
  return verifyTfaCodeserver(tfaCode);
}

export async function doGetMessengerChannels(): Promise<any> {
  return getMessengerChannels();
}

export async function doLoadChannelDetails(channelId: string): Promise<any> {
  return getChannelDetails(channelId);
}

export async function findPropKeysByTenant(
  requestObj: JAWConfigByTenantRequest
): Promise<JAWConfig> {
  return findPropKeysByTenantServer(requestObj);
}

export async function saveTenantConfig(requestObj: JAWConfig): Promise<any> {
  return saveTenantConfigServer(requestObj);
}

export async function translateCustomTemplateMessage(
  requestObj: TranslationTextDto
): Promise<any> {
  return translateTextServer(requestObj);
}

export async function translatePropText(
  requestObj: TranslationPropTextDto
): Promise<any> {
  return translatePropTextServer(requestObj);
}

export async function translateChatText(
  requestObj: TranslationPropTextDto
): Promise<any> {
  return translateChatTextServer(requestObj);
}

export async function saveCustomTemplate(
  requestObj: CreateTemplateMessageDto
): Promise<any> {
  return saveCustomTemplateServer(requestObj);
}
export async function getCustomTemplateLabels(): Promise<any> {
  return getCustomTemplateLabelServer();
}

export async function loadJobsForExternalChat(
  extId: string
): Promise<ILabelValue[]> {
  return loadJobsForExternalChatServer(extId);
}

export async function loadTenantsForExternalChat(
  extId: string
): Promise<ILabelValue[]> {
  return loadTenantsForExternalChatServer(extId);
}

export async function startChatAfterJobSelection(
  extId: string,
  guid: string,
  identifier: string
): Promise<any> {
  return startChatAfterJobSelectionServer(extId, guid, identifier);
}

export async function isSuperAdminATSLoginAllowed(
  guid: string
): Promise<SuperAdminATSLoginDto> {
  return isSuperAdminATSLoginAllowedServer(guid);
}

export async function getJobTemplateSteps(
  templateSlug: string
): Promise<JAWStepTemplate[]> {
  return getJobTemplateStepsServer(templateSlug);
}

export async function getAdCampaigns(requestObj: AdCampaignRequestDto) {
  return getAdCampaignsServer(requestObj);
}

export async function getAdCampaignJobs() {
  return getAdCampaignJobsServer();
}

export async function getAdCampaignJobSites(jobSlug: string) {
  return getAdCampaignJobSitesServer(jobSlug);
}

export async function saveAdCampaignInfo(requestObj: AdCampaignInfoRequestDto) {
  return saveAdCampaignInfoServer(requestObj);
}

export async function getAdCampaignInfo(referenceId: string) {
  return getAdCampaignInfoServer(referenceId);
}

export async function checkRemoteSystemParams(
  requestObj: OnboardRemoteSystemParamsRequestDto
) {
  return checkRemoteSystemParamsServer(requestObj);
}

export async function fetchKleinanzeigenJobPostings(
  requestObj: KleinAnzeigenPostingRequestDto
) {
  return fetchKleinanzeigenJobPostingsServer(requestObj);
}

export async function saveDefaultText(
  requestObj: JAWPropertyConfig
): Promise<JAWPropertyConfig> {
  return saveDefaultTextServer(requestObj);
}

export async function getFinalizeAtDate(interviewGuid: string) {
  return getFinalizeAtDateServer(interviewGuid);
}

export async function updateMessageText(
  request: BulkMessageRequestDto
): Promise<any> {
  return updateMessageTextServer(request);
}

export async function processPagePermission(
  request: MetaLeadAuthCodeDto
): Promise<any> {
  return processPagePermissionServer(request);
}

export async function getPagePermission(): Promise<PagePermissionDto[]> {
  return getPagePermissionsServer();
}

export async function uploadStartMessageMedia(formData: Object): Promise<any> {
  return uploadStartMessageMediaServer(formData);
}

export async function deleteStartMessageMedia(): Promise<any> {
  return deleteStartMessageMediaServer();
}

export async function uploadStartMessageMediaForJob(requestObj: StartMessageMediaJobRequest): Promise<any> {
  return uploadStartMessageMediaForJobServer(requestObj);
}

export async function deleteStartMessageMediaForJob(jobSlug: string): Promise<any> {
  return deleteStartMessageMediaForJobServer(jobSlug);
}

export async function applyAiDisclaimer(request: AiDisclaimerRequestDto) {
  return applyAiDisclaimerServer(request);
}

export async function fetchBenchmarkData() {
  return fetchBenchmarkDataServer();
}